import { SelectOption } from "piramis-base-components/src/logic/types";
import i18n from "@/i18n/i18n";

export const apidata: ApiData = [
  {
    method: 'getinfo',
    type: "POST",
    description: i18n.t('api_docs_method_getinfo_description').toString(),
    response: {
      type: "simple",
      items: [
        {
          key: "chat_id",
          type: 'number',
          description: i18n.t("api_docs_param_chat_id").toString(),
          required: null
        },
        {
          key: "member_count",
          type: 'number',
          description: i18n.t("api_docs_param_member_count").toString(),
          required: null
        },
        {
          key: "group_title",
          type: 'string',
          description: i18n.t("api_docs_param_group_title").toString(),
          required: null
        },
        {
          key: "group_code",
          type: 'string',
          description: i18n.t("api_docs_param_group_code").toString(),
          required: null
        },
        {
          key: "group_photo",
          type: 'string',
          description: i18n.t("api_docs_param_group_photo").toString(),
          required: null
        },
        {
          key: "group_description",
          type: 'string',
          description: i18n.t("api_docs_param_group_description").toString(),
          required: null
        },
        {
          key: "join_by_request",
          type: 'boolean',
          description: i18n.t("api_docs_param_join_by_request").toString(),
          required: null
        },
        {
          key: "join_to_send_messages",
          type: 'boolean',
          description: i18n.t("api_docs_param_join_to_send_messages").toString(),
          required: null
        }
      ]
    }
  },
  {
    method: 'getconfig',
    type: "POST",
    description: i18n.t('api_docs_method_getconfig_description').toString(),
    response: {
      type: "simple",
      items: [
        {
          key: "achievements",
          type: 'object[ ]',
          description: i18n.t("api_docs_method_getconfig_response_achievements").toString(),
          required: null,
          params: {
            type: "simple",
            items: [
              {
                key: "id",
                type: 'number',
                description: i18n.t("api_docs_method_getconfig_response_achievements_id").toString(),
                required: null,
              },
              {
                key: "name",
                type: 'string',
                description: i18n.t("api_docs_method_getconfig_response_achievements_name").toString(),
                required: null,
              }
            ]
          }
        },
        {
          key: "triggers",
          type: 'object[ ]',
          description: i18n.t("api_docs_method_getconfig_response_triggers").toString(),
          required: null,
          params: {
            type: "simple",
            items: [
              {
                key: "id",
                type: 'number',
                description: i18n.t("api_docs_method_getconfig_response_triggers_id").toString(),
                required: null,
              },
              {
                key: "name",
                type: 'string',
                description: i18n.t("api_docs_method_getconfig_response_triggers_name").toString(),
                required: null,
              }
            ]
          }
        },
      ]
    }
  },
  {
    method: 'getuserstatus',
    type: "POST",
    description: i18n.t('api_docs_method_getuserstatus_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        }
      ]
    },
    response: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString(),
          required: null
        },
        {
          key: "language_code",
          type: 'number',
          description: i18n.t("api_docs_param_language_code").toString(),
          required: null
        },
        {
          key: "login",
          type: 'string',
          description: i18n.t("api_docs_param_login").toString(),
          required: null
        },
        {
          key: "name",
          type: 'string',
          description: i18n.t("api_docs_param_name").toString(),
          required: null
        },
        {
          key: "status",
          type: 'enum',
          description: i18n.t("api_docs_param_status").toString(),
          required: null
        },
        {
          key: "checking",
          type: 'boolean',
          description: i18n.t("api_docs_param_checking").toString(),
          required: null
        },
      ]
    }
  },
  {
    method: 'getuserinfo',
    type: "POST",
    description: i18n.t('api_docs_method_getuserinfo_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        }
      ]
    },
    response: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString(),
          required: null
        },
        {
          key: "language_code",
          type: 'number',
          description: i18n.t("api_docs_param_language_code").toString(),
          required: null
        },
        {
          key: "login",
          type: 'string',
          description: i18n.t("api_docs_param_login").toString(),
          required: null
        },
        {
          key: "name",
          type: 'string',
          description: i18n.t("api_docs_param_name").toString(),
          required: null
        },
        {
          key: "message_count",
          type: 'number',
          description: i18n.t("api_docs_method_getuserinfo_param_message_count").toString(),
          required: null
        },
        {
          key: "days_with_messages",
          type: 'number',
          description: i18n.t("api_docs_method_getuserinfo_param_days_with_messages").toString(),
          required: null
        },
        {
          key: "days_in_group",
          type: 'number',
          description: i18n.t("api_docs_method_getuserinfo_param_days_in_group").toString(),
          required: null
        },
        {
          key: "achievements",
          type: 'object[ ]',
          description: i18n.t("api_docs_response_achievements").toString(),
          required: null,
          params: {
            type: "simple",
            items: [
              {
                key: "id",
                type: 'number',
                description: i18n.t("api_docs_response_achievements_id").toString(),
                required: null,
              },
              {
                key: "name",
                type: 'string',
                description: i18n.t("api_docs_response_achievements_name").toString(),
                required: null,
              }
            ]
          }
        },
        {
          key: "points",
          type: 'number',
          description: i18n.t("api_docs_param_points").toString(),
          required: null
        },
        {
          key: "reputation",
          type: 'number',
          description: i18n.t("api_docs_param_reputation").toString(),
          required: null
        },
      ]
    }
  },
  {
    method: 'changereputation',
    type: "POST",
    description: i18n.t('api_docs_method_changereputation_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "value",
          type: 'number',
          description: i18n.t('api_docs_method_changereputation_param_value').toString()
        },
        {
          key: "lower_limit",
          type: 'number',
          description: i18n.t('api_docs_param_lower_limit').toString()
        },
        {
          key: "upper_limit",
          type: 'number',
          description: i18n.t('api_docs_param_upper_limit').toString()
        },
        {
          key: "reason",
          type: 'string',
          description: i18n.t('api_docs_param_reason').toString(),
          required: false
        }
      ]
    },
    response: {
      type: "simple",
      items: [
        {
          key: "data",
          type: 'object',
          required: null,
          params: {
            type: "simple",
            items: [
              {
                key: "user_id",
                type: 'number',
                description: i18n.t('api_docs_param_user_id').toString(),
                required: null,
              },
              {
                key: "points",
                type: 'number',
                description: i18n.t('api_docs_param_points').toString(),
                required: null,
              },
              {
                key: "reputation",
                type: 'number',
                description: i18n.t('api_docs_param_reputation').toString(),
                required: null,
              },
            ]
          }
        },
        {
          key: "procesed",
          type: 'boolean',
          description: i18n.t('api_docs_param_procesed').toString(),
          required: null,
        },
      ]
    }
  },
  {
    method: 'changepoints',
    type: "POST",
    description: i18n.t('api_docs_method_changepoints_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "value",
          type: 'number',
          description: i18n.t('api_docs_method_changepoints_param_value').toString()
        },
        {
          key: "lower_limit",
          type: 'number',
          description: i18n.t('api_docs_param_lower_limit').toString()
        },
        {
          key: "upper_limit",
          type: 'number',
          description: i18n.t('api_docs_param_upper_limit').toString()
        },
        {
          key: "reason",
          type: 'string',
          description: i18n.t('api_docs_param_reason').toString(),
          required: false
        },
        {
          key: "timeout",
          type: 'number',
          description: i18n.t('api_docs_method_changepoints_param_timeout').toString(),
          required: false
        },
        {
          key: "disable_negative",
          type: 'boolean',
          description: i18n.t('api_docs_method_changepoints_param_disable_negative').toString(),
          required: false
        }
      ]
    },
    response: {
      type: "simple",
      items: [
        {
          key: "data",
          type: 'object',
          required: null,
          params: {
            type: "simple",
            items: [
              {
                key: "user_id",
                type: 'number',
                description: i18n.t('api_docs_param_user_id').toString(),
                required: null,
              },
              {
                key: "points",
                type: 'number',
                description: i18n.t('api_docs_param_points').toString(),
                required: null,
              },
              {
                key: "reputation",
                type: 'number',
                description: i18n.t('api_docs_param_reputation').toString(),
                required: null,
              },
            ]
          }
        },
        {
          key: "procesed",
          type: 'boolean',
          description: i18n.t('api_docs_param_procesed').toString(),
          required: null,
        },
      ]
    }
  },
  // {
  //   method: 'transfer_license',
  //   type: "POST",
  //   description: i18n.t('api_docs_method_transfer_license_description').toString(),
  //   params: {
  //     type: "simple",
  //     items: [
  //       {
  //         key: "creator",
  //         type: 'number',
  //         description: i18n.t('api_docs_method_transfer_license_param_creator').toString()
  //       },
  //       {
  //         key: "target",
  //         type: 'number ',
  //         description: i18n.t('api_docs_method_transfer_license_param_target').toString()
  //       },
  //     ]
  //   }
  // },
  {
    method: 'send_chat_message',
    type: "POST",
    description: i18n.t('api_docs_method_send_chat_message_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "message",
          type: 'array',
          description: i18n.t('send_chat_message_param_messages').toString(),
          params: {
            type: "simple",
            items: [
              {
                key: "text",
                type: "string",
                description: i18n.t('send_chat_message_param_messages_text').toString(),
              },
              {
                key: "disable_link_preview",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_disable_link_preview').toString(),
              },
              {
                key: "pin",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_pin').toString(),
              },
              {
                key: "disable_notify",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_disable_notify').toString(),
              },
              {
                key: "protect_content",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_protect_content').toString(),
              },
              {
                key: "remove_after",
                type: "number",
                required: false,
                description: i18n.t('send_chat_message_param_messages_remove_after').toString(),
              },
              {
                key: "buttons",
                type: "object[ ][ ]",
                required: false,
                description: i18n.t('send_chat_message_param_messages_buttons').toString(),
                params: {
                  type: "variation",
                  items: {
                    title: "api_docs_buttons_",
                    options: [
                      {
                        label: 'Link',
                        value: 'Link',
                      },
                      {
                        label: 'Reaction',
                        value: 'Reaction',
                      },
                    ],
                    field: {
                      "Link": {
                        type: "simple",
                        items: [
                          {
                            key: "type",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_type_link').toString(),
                          },
                          {
                            key: "text",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_text').toString(),
                          },
                          {
                            key: "link",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_link_link').toString(),
                          }
                        ]
                      },
                      "Reaction": {
                        type: "simple",
                        items: [
                          {
                            key: "type",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_type_reaction').toString(),
                          },
                          {
                            key: "text",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_text').toString(),
                          },
                          {
                            key: "onlyUnique",
                            type: "boolean",
                            description: i18n.t('send_chat_message_param_messages_buttons_reaction_only_unique').toString(),
                          },
                          {
                            key: "message",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_reaction_message').toString(),
                          }
                        ]
                      }
                    }
                  }
                }
              },
            ]
          }
        },
        {
          key: "actor",
          type: 'number',
          description: i18n.t('send_chat_message_param_actor').toString(),
          required: false,
        },
      ]
    }
  },
  {
    method: 'change_achievements',
    type: "POST",
    description: i18n.t('api_docs_method_change_achievements_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "achievements",
          type: 'number[ ]',
          description: i18n.t('api_docs_method_change_achievements_param_achievements').toString()
        },
        {
          key: "mode",
          type: 'enum',
          description: i18n.t('api_docs_method_change_achievements_param_mode').toString()
        },
      ]
    },
    response: {
      type: "simple",
      items: [
        {
          key: "achievements",
          type: 'object[ ]',
          description: i18n.t("api_docs_response_achievements").toString(),
          required: null,
          params: {
            type: "simple",
            items: [
              {
                key: "id",
                type: 'number',
                description: i18n.t("api_docs_response_achievements_id").toString(),
                required: null,
              },
              {
                key: "name",
                type: 'string',
                description: i18n.t("api_docs_response_achievements_name").toString(),
                required: null,
              }
            ]
          }
        },
      ]
    }
  },
  {
    method: 'run_trigger',
    type: "POST",
    description: i18n.t('api_docs_method_run_trigger_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "trigger_id",
          type: 'number',
          description: i18n.t('api_docs_method_run_trigger_param_trigger_id').toString()
        },
        {
          key: "actor",
          type: 'number',
          description: i18n.t('api_docs_method_run_trigger_param_actor').toString()
        },
      ]
    }
  },
  {
    method: 'complete_captcha',
    type: "POST",
    description: i18n.t('api_docs_method_complete_captcha_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
      ]
    }
  },
  {
    method: 'mute',
    type: "POST",
    description: i18n.t('api_docs_method_mute_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "time",
          type: 'number',
          description: i18n.t("api_docs_param_time").toString()
        },

      ]
    }
  },
  {
    method: 'ban',
    type: "POST",
    description: i18n.t('api_docs_method_ban_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "time",
          type: 'number',
          description: i18n.t("api_docs_param_time").toString()
        },
        {
          key: "remove_messages",
          type: 'boolean',
          description: i18n.t("api_docs_method_ban_param_remove_messages").toString()
        },
      ]
    }
  },
  {
    method: 'kick',
    type: "POST",
    description: i18n.t('api_docs_method_kick_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
      ]
    }
  }
]

export type ApiData = Array<ApiMethod>

export type ApiMethod = {
  method: string
  type: "POST" | "GET"
  description: string
  params?: SimpleParams | VariationParams
  response?: SimpleParams | VariationParams
}

type SimpleParams = {
  type: "simple"
  items: Array<RequestParam>
}

type VariationParams = {
  type: "variation"
  items: VariationField
}

type RequestParam = {
  key: string
  type: string
  description?: string
  required?: boolean | null
  params?: SimpleParams | VariationParams
}

type VariationField = {
  title: string
  options: Array<SelectOption>
  field: Record<string, SimpleParams | VariationParams>
}
